import CryptoJS from "crypto-js";
import API from "../constants/API";
import axios from "axios";

const gf_publicKey = "f9bac33399";
const gf_privateKey = "8c4b4d9f87c8942";
const d = new Date();
const expiration = 86400;
const unixtime = parseInt(d.getTime() / 1000);
const future_unixtime = unixtime + expiration;
const route = `forms/2/submissions`;
const method = "POST";
const stringToSign = `${gf_publicKey}:${method}:${route}:${future_unixtime}`;

const calculateSig = (stringToSign, privateKey) => {
	const hash = CryptoJS.HmacSHA1(stringToSign, privateKey);
	const base64 = hash.toString(CryptoJS.enc.Base64);
	return encodeURIComponent(base64);
};

const sig = calculateSig(stringToSign, gf_privateKey);

export async function proccessSignInSubmission(values, callback) {
	values.input_values.input_4 = d.toLocaleString();

	const request = await axios.post(
		`${
			API.gformApiUri
		}/${route}/?api_key=${gf_publicKey}&signature=${sig}&expires=${future_unixtime}`,
		JSON.stringify(values)
	);

	if (request.data.response.is_valid) {
		callback();
	}
}
