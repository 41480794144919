import React, { useState } from "react";
import {
	ScrollView,
	StyleSheet,
	View,
	Image,
	Text,
	StatusBar,
	TouchableOpacity,
	Linking
} from "react-native";
import { Button } from "react-native-elements";
import { Ionicons } from "@expo/vector-icons";
import Colors from "../../constants/Colors";
import SignInForm from "./SignInForm";
import { OpenSansText } from "../../components/OpenSansText";

export default function HomeScreen() {
	const [isModalOpen, setModalVisibility] = useState(false);

	return (
		<View style={styles.container}>
			<StatusBar barStyle="light-content" />
			{renderHeroArea()}
			{renderTabArea()}
			<ScrollView
				style={styles.container}
				contentContainerStyle={styles.contentContainer}
			>
				<Button
					title="Sign In"
					buttonStyle={{
						backgroundColor: Colors.tintColor,
						paddingVertical: 15
					}}
					containerStyle={{
						marginBottom: 25,
						width: "90%"
					}}
					titleStyle={{ fontFamily: "open-sans", fontSize: 25 }}
					onPress={() => openModal(setModalVisibility)}
				/>
				{renderBottomContent()}
			</ScrollView>
			<SignInForm
				isModalOpen={isModalOpen}
				closeModal={() => closeModal(setModalVisibility)}
			/>
		</View>
	);
}

const renderHeroArea = () => (
	<View style={{ width: "100%", height: "50%" }}>
		<Image
			source={require("../../assets/images/carousel-images/homeaway.jpg")}
			style={{ width: "100%", height: "100%" }}
			resizeMode="cover"
		/>
		<View style={styles.overlayTextWrapper}>
			<Text style={{ fontSize: 70, color: "#FFF", textAlign: "right" }}>
				WOO
			</Text>
			<OpenSansText style={styles.overlayText}>If there's a WOO</OpenSansText>
			<OpenSansText style={styles.overlayText}>there's a way</OpenSansText>
		</View>
	</View>
);

const renderTabArea = () => (
	<View style={styles.iconBar}>
		<TouchableOpacity
			style={styles.iconBarItem}
			onPress={() =>
				Linking.openURL(
					`mailto:ksmith@thewoo.com?subject=Freelancer Kios App Query`
				)
			}
		>
			<View style={styles.iconBarItemView}>
				<Ionicons name="ios-mail" size={32} color={Colors.tintColor} />
				<Text style={styles.iconBarItemText}>Email</Text>
			</View>
		</TouchableOpacity>
		<TouchableOpacity
			style={styles.iconBarItem}
			onPress={() => Linking.openURL(`tel:+13105581188`)}
		>
			<View style={styles.iconBarItem}>
				<Ionicons
					name="ios-phone-portrait"
					size={32}
					color={Colors.tintColor}
				/>
				<Text style={styles.iconBarItemText}>Call</Text>
			</View>
		</TouchableOpacity>
	</View>
);

const renderBottomContent = () => (
	<View style={styles.textContent}>
		<View style={styles.textContentView}>
			<OpenSansText style={styles.textContentHeading}>
				Freelancer Kiosk
			</OpenSansText>
		</View>
		<View style={styles.textContentView}>
			<Text style={styles.bulletNumber}>1</Text>
			<OpenSansText style={styles.textContentItem}>
				Sed ut perspiciatis unde omnis iste natus error sit voluptatem.
			</OpenSansText>
		</View>
		<View style={styles.textContentView}>
			<Text style={styles.bulletNumber}>2</Text>
			<OpenSansText style={styles.textContentItem}>
				Nemo enim ipsam voluptatem quia voluptas sit aspernatur
			</OpenSansText>
		</View>
		<View style={styles.textContentView}>
			<Text style={styles.bulletNumber}>3</Text>
			<OpenSansText style={styles.textContentItem}>
				Sed ut perspiciatis unde omnis iste natus error sit voluptatem.
			</OpenSansText>
		</View>
		<View style={styles.textContentView}>
			<Text style={styles.bulletNumber}>4</Text>
			<OpenSansText style={styles.textContentItem}>
				Sed ut perspiciatis unde omnis iste natus error sit voluptatem.
			</OpenSansText>
		</View>
		<View style={[styles.textContentView, { borderBottomWidth: 0 }]}>
			<Text style={styles.bulletNumber}>5</Text>
			<OpenSansText style={styles.textContentItem}>
				Sed ut perspiciatis unde omnis iste natus error sit voluptatem.
			</OpenSansText>
		</View>
	</View>
);

const openModal = setModalVisibility => {
	setModalVisibility(true);
};

const closeModal = setModalVisibility => {
	setModalVisibility(false);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#fff",
		backgroundColor: "rgba(0, 0 ,0, .03)"
	},
	contentContainer: {
		paddingTop: 30,
		alignItems: "center"
	},
	overlayTextWrapper: {
		position: "absolute",
		bottom: 25,
		left: 25
	},
	overlayText: {
		fontSize: 15,
		color: "#FFF",
		textAlign: "left",
		marginLeft: 5
	},
	iconBar: {
		height: 80,
		backgroundColor: "#FFF",
		alignSelf: "stretch",
		flexDirection: "row",
		alignItems: "center"
	},
	iconBarItem: {
		width: "50%",
		alignItems: "center"
	},
	iconBarItemView: {
		width: "100%",
		alignItems: "center"
	},
	iconBarItemText: {
		fontSize: 14,
		color: Colors.tintColor
	},
	textContent: {
		width: "90%",
		padding: 15,
		backgroundColor: "#FFF",
		borderRadius: 5
	},
	textContentView: {
		borderBottomColor: "rgba(1, 1, 1, .1)",
		borderBottomWidth: 1,
		paddingVertical: 20,
		paddingHorizontal: 15,
		flexDirection: "row",
		alignItems: "center"
	},
	bulletNumber: {
		fontSize: 16,
		marginRight: 15,
		color: Colors.tintColor
	},
	textContentItem: {
		fontSize: 18,
		lineHeight: 25
	},
	textContentHeading: {
		width: "100%",
		fontSize: 25,
		textAlign: "center",
		paddingBottom: 20
	}
});

HomeScreen.navigationOptions = {
	header: null
};
