import React from "react";
import { View, StyleSheet, Text } from "react-native";
import { Field } from "formik";
import InputField from "./InputField";
import { Button } from "react-native-elements";
import Colors from "../../constants/Colors";

const Form = ({ handleSubmit, isValid, isSubmitting }) => {
	return (
		<View style={{ flex: 1 }}>
			<View style={styles.formBlock}>
				<Field component={InputField} name="input_1" placeholder="Name" />
			</View>
			<View style={styles.formBlock}>
				<Field component={InputField} name="input_2" placeholder="Phone" />
			</View>
			<View style={styles.formBlock}>
				<Field
					component={InputField}
					name="input_3"
					placeholder="Note"
					multiline
				/>
			</View>
			<View style={styles.formBlock}>
				<Button
					title="SUBMIT"
					buttonStyle={styles.buttonStyle}
					titleStyle={{ color: "#FFF" }}
					loading={isSubmitting}
					onPress={handleSubmit}
				/>
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	formBlock: {
		padding: 10
	},
	inputStyle: {
		marginBottom: 15
	},
	inputIconStyle: {
		marginRight: 10,
		marginLeft: 5
	},
	buttonStyle: {
		borderColor: "#000",
		paddingVertical: 15,
		backgroundColor: Colors.primaryColor,
		borderRadius: 5
	}
});

export default Form;
