import React, { useState } from "react";
import { View, Platform, StyleSheet, TouchableOpacity } from "react-native";
import { Formik } from "formik";
import * as Yup from "yup";
import { proccessSignInSubmission } from "../../actions/GravityForm";
import Form from "./Form";
import Modal from "react-native-modalbox";
import { Ionicons } from "@expo/vector-icons";
import { OpenSansText } from "../../components/OpenSansText";

const SignInForm = ({ isModalOpen, closeModal }) => {
	const [didSubmit, setSubmitStatus] = useState(false);

	return (
		<Modal
			style={[styles.modalDrawer, didSubmit ? { height: 120 } : {}]}
			position="bottom"
			isOpen={isModalOpen}
			keyboardTopOffset={Platform.OS === "ios" ? 22 : 0}
			onClosed={() => {
				closeModal();
				setSubmitStatus(false);
			}}
		>
			<TouchableOpacity
				onPress={() => {
					closeModal();
				}}
			>
				<View
					style={{
						height: 40,
						flexDirection: "row",
						justifyContent: "space-around",
						alignItems: "center",
						borderBottomColor: "rgba(1, 1, 1, .1)",
						borderBottomWidth: 1,
						backgroundColor: "rgba(0, 0 ,0, .02)"
					}}
				>
					<Ionicons
						name="ios-arrow-down"
						size={25}
						style={{ marginRight: "auto", marginLeft: 15 }}
					/>
					{!didSubmit && (
						<OpenSansText
							style={{ fontSize: 20, textAlign: "center", width: "100%" }}
						>
							Please fill out the form
						</OpenSansText>
					)}
				</View>
			</TouchableOpacity>
			{!didSubmit ? (
				<Formik
					initialValues={{ input_1: "", input_2: "" }}
					onSubmit={(props, actions) => {
						const wrapped_values = { input_values: props };
						proccessSignInSubmission(wrapped_values, () => {
							actions.setSubmitting(false);
							setSubmitStatus(true);
						});
					}}
					validationSchema={FormSchema}
					component={Form}
				/>
			) : (
				<View
					style={{
						flexDirection: "row",
						justifyContent: "center",
						alignItems: "center",
						marginBottom: 25
					}}
				>
					<Ionicons name="ios-checkmark-circle" size={35} color="green" />
					<OpenSansText
						style={{ fontSize: 20, textAlign: "center", marginLeft: 10 }}
					>
						Successfully signed in!
					</OpenSansText>
				</View>
			)}
		</Modal>
	);
};

const FormSchema = Yup.object().shape({
	input_1: Yup.string().required("This field is required.")
});

const styles = StyleSheet.create({
	modalDrawer: {
		height: 320,
		borderTopLeftRadius: 5,
		borderTopRightRadius: 5,
		justifyContent: "space-between"
	}
});

export default SignInForm;
