import React from "react";
import { Input } from "react-native-elements";
import { View, Text, StyleSheet } from "react-native";

const InputField = ({
	field: { name, value, onChange },
	form: { errors, touched, setFieldTouched },
	multiline,
	autoCorrect,
	placeholder
}) => {
	return (
		<View>
			<Input
				onChangeText={onChange(name)}
				value={value}
				autoCorrect={autoCorrect ? true : false}
				multiline={multiline}
				placeholder={placeholder}
				errorMessage={touched[name] && errors[name] ? errors[name] : ""}
				errorStyle={styles.errorText}
				onBlur={() => setFieldTouched(name)}
			/>
		</View>
	);
};

const styles = StyleSheet.create({
	errorText: {
		color: "red",
		fontSize: 10
	}
});

export default InputField;
